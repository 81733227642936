import axios from 'axios'

export default {
  namespaced: true,

  state: {
    actors: []
  },

  getters: {
    getActors: state => state.actors,
    getAsInputOptions (context) {
      return context.actors.map(item => {
        return {
          text: item.name,
          value: item.slug
        }
      })
    },
    getAvatars (context) {
      return context.actors.reduce((acc, { slug, icon }) => {
        acc[slug] = icon
        return acc
      }, {})
    }
  },

  mutations: {
    sync: (state, payload) => {
        state.actors = payload
    }
  },

  actions: {
    getForGameTest (context) {
      return context.actors.map(item => {
        return {
          text: item.name,
          value: item.slug
        }
      })
    },
    get: async (context) => {
      const args = {
        action: 'mshot_get_all_game_actors',
        'game-slug': context.rootState.gameSetting.game_slug
      }
      const response = await axios.post(`${context.rootState.gameSetting.base_url}/moonshot-game`, args)
      context.commit('sync', response.data.data)
      console.log('syncbadges', response)
    },
    update: async (context, args) => {
      args.action = 'mshot_sync_game_actors'
      args['game-slug'] = context.rootState.gameSetting.game_slug

      const response = await axios.post(`${context.rootState.gameSetting.base_url}/moonshot-game`, args)
      context.commit('sync', response.data)
      console.log('syncbadges', response)
    }
  }

}

export default {
  namespaced: true,

  state: {
    name: '',
    session_id: '',
    avatar: {},
    badges: [],
    score: null,
    userSettings: {}
  },

  getters: {
    getAvatar: state => state.avatar,
    getName: state => state.name,
    getScore: state => state.score,
    getBadges: state => state.badges,
    getSessionId: state => state.session_id,
    getShareUrl: (state, getters, rootState, rootGetters) => {
      const baseUrl = rootGetters['gameSetting/getBaseUrl']
      return baseUrl + '/galifakta-game-share/' + state.session_id
    },
    getUserSettings: state => state.userSettings
  },

  mutations: {
    setAvatar: (state, payload) => {
      state.avatar = payload
    },
    setSessionId: (state, payload) => {
      state.session_id = payload
    },
    setName: (state, payload) => {
      state.name = payload
    },
    pushBadge: (state, payload) => {
      const sameBadge = state.badges.find(item => item === payload)
      if (!sameBadge) {
        state.badges.push(payload)
      }
    },
    setScore: (state, payload) => {
      state.score = payload
    },
    resetBadge: (state) => {
      state.badges = []
    },
    setUserSettings: (state, payload) => {
      state.userSettings = payload
    }
  },

  actions: {
    resetPlayerStat (context) {
      context.commit('resetBadge')
      context.commit('setScore', null)
    }
  }
}

import axios from 'axios'

export default {
  namespaced: true,

  state: {
    badges: [],
    rooms: [],
    actors: [],
    base_url: null,
    game_slug: 'game',
    max_progress: null,
    max_point: null,
    skin: null,
    config: null,
    mode: null,
    end_mode: 'v1'
  },

  getters: {
    getBadges: state => state.badges,
    getBaseUrl: state => state.base_url,
    getGameSlug: state => state.game_slug,
    getMaxProgress: state => state.max_progress,
    getMaxPoint: state => state.max_point,
    getSkin: state => state.skin,
    getMode: state => state.mode,
    getConfig: state => state.config,
    getEndMode: state => state.end_mode,
    configLoaded: function (state) {
      if (state.config) {
        return true
      }
      return false
    }
  },

  mutations: {
    syncBadges: (state, payload) => {
        state.badges = payload
    },
    setBaseUrl: (state, payload) => {
      state.base_url = payload
    },
    setGameSlug: (state, payload) => {
      state.game_slug = payload
    },
    setMaxPoint: (state, payload) => {
      state.max_point = payload
    },
    setMaxProgress: (state, payload) => {
      state.max_progress = payload
    },
    setGameSkin: (state, payload) => {
      state.skin = payload
    },
    setGameMode: (state, payload) => {
      state.mode = payload
    },
    setConfig: (state, payload) => {
      state.config = payload
    },
    setEndMode: (state, payload) => {
      state.end_mode = payload
    }
  },

  actions: {
    getBadges: async (context) => {
      const args = {
        action: 'mshot_get_all_game_badge',
        'game-slug': context.state.game_slug
      }
      const response = await axios.post(`${context.state.base_url}/moonshot-game`, args)
      context.commit('syncBadges', response.data)
    },
    updateBadges: async (context) => {
      const args = {
        action: 'mshot_get_all_game_badge',
        'game-slug': context.state.game_slug
      }
      const response = await axios.post(`${context.state.base_url}/moonshot-game`, args)
      context.commit('syncBadges', response.data)
    },
    setGameSlug: function (context) {
        /**
         * grab the current url SLUG
         * send it as post data to server
         *
         * .replace only find and replace first occurance
         * with regex, we replace every / in the slug
         */

        let slug = window.location.pathname
        slug = slug.replaceAll('/', '')

        /**
         * default to /game
         */
        if (slug === '') {
          slug = 'game'
        }

        // return 'mstage'
        context.commit('setGameSlug', slug)
    },

    setBaseUrl: async (context, payload) => {
      const response = await axios.get(`${window.location.href.split('?')[0]}/config.json`)
      context.commit('setBaseUrl', response.data.API)
      context.commit('setConfig', response.data)
      context.dispatch('setGameSlug')
      context.dispatch('badges/getBadges', null, { root: true })
      context.dispatch('avatars/get', null, { root: true })
      context.commit('setGameSkin', (response.data?.version) ? response.data?.version : 'glo')
      context.commit('setGameMode', (response.data?.mode) ? response.data?.mode : 'normal')
      context.commit('setEndMode', (response.data?.end_mode) ? response.data?.end_mode : 'v1')

      const args = {
        action: 'get_max_point',
        'game-slug': context.rootState.gameSetting.game_slug
      }

      const response2 = await axios.post(`${context.rootState.gameSetting.base_url}/moonshot-game`, args)
      context.commit('setMaxPoint', response2.data.max_point)
      context.commit('setMaxProgress', response2.data.max_progress)
    }
  }

}
